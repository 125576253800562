import * as React from 'react';

// --- Lock Chapter component TEMPORARY | Change height and lg-height from first div to shorten lock view
const LockChapters = () => {

    return (
        <div className='absolute z-20 lg:h-[49%] flex flex-col h-[48.5%] w-full bottom-0 border-t-[1px] lg:border-t-2 lockBlur'>
            <div className='flex justify-end w-full px-5 mt-2 lg:mt-4 lg:px-36 h-14'>
                <div className='flex flex-col items-end w-56 text-xs text-white lg:text-lg font-IBM'>
                    <div className='flex flex-row items-center uppercase'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17.355" height="22.779" viewBox="0 0 17.355 22.779" className='scale-[0.6] lg:scale-100'>
                            <g id="Raggruppa_158" data-name="Raggruppa 158" transform="translate(-1665.5 -1028)">
                                <path id="lock_FILL0_wght400_GRAD0_opsz48" d="M9.627,24.779A1.62,1.62,0,0,1,8,23.152V11.383A1.62,1.62,0,0,1,9.627,9.756h1.9v-2.6A4.967,4.967,0,0,1,13.03,3.505,4.967,4.967,0,0,1,16.678,2a4.967,4.967,0,0,1,3.647,1.505A4.967,4.967,0,0,1,21.83,7.152v2.6h1.9a1.62,1.62,0,0,1,1.627,1.627V23.152a1.62,1.62,0,0,1-1.627,1.627Zm0-1.627h14.1V11.383H9.627Zm7.051-3.8a2.036,2.036,0,0,0,1.478-.6,1.937,1.937,0,0,0,.61-1.437,2.135,2.135,0,0,0-.61-1.478,1.976,1.976,0,0,0-2.956,0,2.135,2.135,0,0,0-.61,1.478,1.937,1.937,0,0,0,.61,1.437A2.036,2.036,0,0,0,16.678,19.355Zm-3.525-9.6H20.2v-2.6a3.4,3.4,0,0,0-1.03-2.495,3.535,3.535,0,0,0-4.99,0,3.4,3.4,0,0,0-1.03,2.495Zm-3.525,13.4v0Z" transform="translate(1657.5 1026)" fill="#fff"/>
                            </g>
                        </svg>
                        <span className="pl-1 lg:pl-3">locked</span>
                    </div>
                    <div className='flex items-center'>- Chapter 2 Coming Soon -</div>
                </div>
            </div>
        </div>
    )

}

export default LockChapters;